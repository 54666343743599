import {
  EXPIRE_COOCKIES_ACTION,
  LOGIN_ACTION,
  PW_RESET_REQUEST,
  LOGOUT_ACTION,
  SESSION_INFO_ACTION,
  UPDATE_BRAND_PARTNER_ACTION,
} from "../constants";
import providersService from "../../utilities/providers";

const INITIAL_STATE = {
  session: JSON.parse(sessionStorage.getItem("session")),
  response: { result: !!sessionStorage.getItem("session") },
  controlVersion: JSON.parse(sessionStorage.getItem("controlVersion")),
};

const SessioninfoReducer = (state = INITIAL_STATE, action) => {
  if (state.session) {
    const { brandSelected, partnerSelected } = state.session;
    const isPoland = brandSelected.id === 24 && partnerSelected.id === 1;
    state.session.isPoland = isPoland;
    const isItaly = ((brandSelected.id === 86 && partnerSelected.id === 1) || (brandSelected.id === 86 && partnerSelected.id === 2));
    state.session.isItaly = isItaly;
    const isKentucky = brandSelected.id === 16 && partnerSelected.id === 1;
    state.session.isKentucky = isKentucky;
  }
  switch (action.type) {
    case LOGIN_ACTION:
      return {
        ...state,
        ...action.payload,
      };
    case PW_RESET_REQUEST:
      return INITIAL_STATE;
    case SESSION_INFO_ACTION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOGOUT_ACTION:
      return {
        ...state,
        session: undefined,
        response: action.payload,
        controlVersion: undefined,
      };
    case EXPIRE_COOCKIES_ACTION:
      return {
        ...state,
        session: undefined,
        response: action.payload,
        controlVersion: undefined,
      };
    case UPDATE_BRAND_PARTNER_ACTION:
      const { session } = state;
      session.brandSelected = providersService.getBrandById(
        session.user.brands,
        action.payload.brandSelected
      );
      session.partnerSelected = providersService.getPartnerById(
        session.brandSelected.partners,
        action.payload.partnerSelected
      );
      sessionStorage.setItem("session", JSON.stringify(session));
      sessionStorage.removeItem("filters");
      return {
        ...state,
        session: { ...session },
      };

    default:
      return state;
  }
};

export default SessioninfoReducer;
